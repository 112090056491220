
const CourseDetailFaqData = [
    {
        id: 1,
        title: ' How long is the Options Trading with Technical Analysis Masterclass?',
        details:'Though you can watch all the lessons and read all the contents in the Options Trading with Technical Analysis Masterclass inside 4- 5 hours, it is highly recommended to give sufficient time to all the lessons. If required go through the lesson again plus apply the practical concepts taught in the live market to develop a better understanding.',
    },
    {
        id: 2,
        title: ' What are the eligibility criteria for taking this course on Options Trading with Technical Analysis Masterclass?',
        details:'We expect you to have entry-level experience in trading options for taking the Options Trading with Technical Analysis Masterclass.',
    },
    {
        id: 3,
        title: ' When will I qualify for the Options Trading with Technical Analysis Masterclass certificate?',
        details:'Upon successful completion of the Options Trading with Technical Analysis Masterclass, you will need to pass the final assessment with a graded quiz to receive the certificate of completion for the Options Trading Masterclass.',
    },
    {
        id: 4,
        title: ' How will this Options Trading with Technical Analysis Masterclass help me?',
        details:'Options Trading with Technical Analysis Masterclass will help you as a beginner to take your trading to the next level with advanced strategies and better trade management capabilities up your sleeve.',
    },
    {
        id: 5,
        title: "Can I join this Options Trading with Technical Analysis Masterclass if I don't have any trading experience?",
        details:"It's a must to have some options trading experience for taking this Options Trading with Technical Analysis Masterclass.",

    },
    {
        id: 6,
        title: ' Who will benefit from this Options Trading with Technical Analysis Masterclass?',
        details:'If taken as instructed Options Trading with Technical Analysis Masterclass will be beneficial to traders of all levels.',
    }
];

export default CourseDetailFaqData