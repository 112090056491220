import videoo from '../../assest/BootsEmpire/demovidio.mp4'
import '../../styles/playnif50/VideoGalleryStyle.css'
import { Box } from '@mui/material';
import BootsEmpireFooter from '../../common/playnif50/Footer/BootsEmpireFooter';
import BootsEmpireHeader from '../../common/playnif50/header/BootsEmpireHeader';


const VideoGallery = () => {
    return (
        <>
            <BootsEmpireHeader />
            <Box>
                <h2 className='vediotag'>Video Gallery</h2>
                <div className='bgdiv'>
                    <div className="vidio-divbox">
                        <video
                            className="video-tagbtn"
                            playsInline
                            muted
                            autoPlay
                            controls
                            alt="All the devices"
                            src={videoo}

                        />
                    </div>
                    <div className="vidio-divbox">
                        <video
                            className="video-tagbtn"
                            playsInline
                            muted
                            autoPlay
                            controls
                            alt="All the devices"
                            src={videoo}

                        />
                    </div>
                    <div className="vidio-divbox">
                        <video
                            className="video-tagbtn"
                            playsInline
                            muted
                            autoPlay
                            controls
                            alt="All the devices"
                            src={videoo}

                        />
                    </div>
                    <div className="vidio-divbox">
                        <video
                            className="video-tagbtn"
                            playsInline
                            muted
                            autoPlay
                            controls
                            alt="All the devices"
                            src={videoo}

                        />
                    </div>
                    <div className="vidio-divbox">
                        <video
                            className="video-tagbtn"
                            playsInline
                            muted
                            autoPlay
                            controls
                            alt="All the devices"
                            src={videoo}

                        />
                    </div>
                </div>
            </Box>
            <BootsEmpireFooter />

        </>
    )
}

export default VideoGallery
