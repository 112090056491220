
import polygon from '../../assest/BootsEmpire/about-us-page-banner.jpg'
import { Box, Button } from "@mui/material"
import '../../styles/playnif50/AboutUsStyle.css'
import BootsEmpireHeader from "../../common/playnif50/header/BootsEmpireHeader"
import BootsEmpireFooter from '../../common/playnif50/Footer/BootsEmpireFooter'
import EnterpriseImg from '../../assest/playnif50/cover.jpg'





const AboutUs = () => {

    return (
        <>
            <BootsEmpireHeader />

            <Box className='Aboutus_topdiv'>
                <img src={polygon} alt="img" />
                <div className="hosp_aboutushead">
                    <h1>About Us</h1>
                </div>
            </Box>

            <div className=" bg-light-subtle about_textdiv">
                <div className="divabutt">
                    <h6 className=' fw-bold'>PlayNif50 India’s Premier Stock Market Academy</h6>
                    <p className="">We stands as a beacon of excellence in stock market education in India. Founded in 2010 by PlayNif50, a distinguished research analyst and option seller, the academy has quickly established itself as the leading institution for comprehensive and advanced stock market training.</p>
                    <img src={EnterpriseImg} alt="img" />
                    <h6 className=' fw-bold my-2'>Founder’s Vision and Expertise</h6>
                    <p>PlayNif50, the visionary behind the academy, brought with him a wealth of knowledge and experience in the stock market. With a sharp analytical mind and a deep understanding of options trading, PlayNif50 goal was to create an educational platform that demystifies the complexities of the stock market and empowers individuals with the skills and knowledge to succeed. His practical approach and dedication to excellence have been instrumental in the academy’s success..</p>
                    <h6 className=' fw-bold my-2'>Joining Forces with PlayNif50</h6>
                    <p>In 2012, the academy welcomed PlayNif50, a renowned technical analyst, as a key member of the team. PlayNif50 brought with him a rich background in technical analysis, further strengthening the academy’s curriculum. Together, PlayNif50 have crafted a unique blend of fundamental and technical analysis education, making the PlayNif50 Academy a one-stop destination for aspiring traders and investors.</p>
                    <h6 className=' fw-bold'>Comprehensive Curriculum</h6>
                    <p>The academy offers a wide range of courses designed to cater to different levels of experience, from beginners to advanced traders. The curriculum includes:.</p>

                    <ul>
                        <li> <strong>Stock Market Fundamentals :</strong> Covering the basics of financial markets, stock exchanges, and fundamental analysis.</li>
                        <li> <strong>Advanced Technical Analysis :</strong> Delving into sophisticated chart patterns, technical indicators, and trading strategies.</li>
                        <li> <strong>Advanced Derivative Analysis :</strong> Exploring futures, options, and other derivatives, along with their pricing and valuation.</li>
                        <li> <strong>Advanced Option Strategies :</strong> Teaching complex options trading strategies and risk management techniques.</li>
                        <li> <strong>Risk Management :</strong> Emphasizing the importance of managing risks in trading and investing.</li>
                    </ul>
                    <p>Each course is meticulously designed to provide a deep understanding of the subject matter, supported by practical assignments and live trading sessions.</p>
                    <h6 className=' fw-bold'>Why PlayNif50 Academy?</h6>
                    <ol>
                        <li> <strong>Expert Instructors: </strong> Learn from PlayNif50, who bring years of practical experience and success in the stock market.</li>
                        <li> <strong>Comprehensive Courses: </strong> A wide range of courses that cover all aspects of stock market trading and investing.</li>
                        <li> <strong>Practical Approach: </strong> Emphasis on real-world applications with live trading sessions and hands-on assignments.</li>
                        <li> <strong>Guest Lectures: </strong> Insights from industry experts, providing students with diverse perspectives and advanced knowledge.</li>
                        <li> <strong>Flexible Learning: </strong> Courses designed to fit into busy schedules, available both online and offline.</li>
                    </ol>
                    <h6 className=' fw-bold'>  Achievements and Recognition</h6>
                    <p>PlayNif50 Academy has trained thousands of students who have gone on to achieve significant success in the stock market. The academy is known for its rigorous training programs, which have received accolades from both students and industry professionals. The academy’s alumni network is a testament to its impact, with many former students now thriving as successful traders, analysts, and financial advisors.</p>
                    <h6 className=' fw-bold'>Enroll Today</h6>
                    <p>Join the PlayNif50 Academy and take the first step towards mastering the stock market. Whether you are a novice looking to understand the basics or an experienced trader aiming to refine your strategies, the academy offers the perfect learning environment to achieve your goals.</p>
                    <p>For more information and enrollment details, visit the PlayNif50 Academy website or contact our admissions office.</p>
                    <p>PlayNif50 Academy – Shaping the future of stock market trading and investing in India, one student at a time..</p>

                </div>
            </div>


            <BootsEmpireFooter />

        </>
    )
}

export default AboutUs