


import polygon from '../../assest/BootsEmpire/about-us-page-banner.jpg'
import { Box } from "@mui/material"
import '../../styles/playnif50/AboutUsStyle.css'
import BootsEmpireHeader from "../../common/playnif50/header/BootsEmpireHeader"
import BootsEmpireFooter from '../../common/playnif50/Footer/BootsEmpireFooter'
import EnterpriseImg from '../../assest/BootsEmpire/slider-02-1.jpg'




const RefundPolicy = () => {

    return (
        <>
            <BootsEmpireHeader />

            <Box className='Aboutus_topdiv'>
                <img src={polygon} alt="img" />
                <div className="hosp_aboutushead">
                    <h1>Refund Policy</h1>
                </div>
            </Box>

            <div className=" bg-light-subtle about_textdiv">
                <div className="">
                    <p className=" fw-bold">1. REFUNDS</p>
                    <p> We will refund any amount left in your Unutilised Account if your Account gets suspended or removed; </p>
                    <ol type='a'>
                        <li>(a) due to Our failure to provide Services,</li>
                        <li>(b) any instruction received from any government or regulatory authority.</li>
                    </ol>
                    <p>We will refund the Pre-Designated Amount paid by You in case any Contest is abandoned in accordance with these Terms.</p>
                    <p>We shall deactivate Your Account pursuant to any direction issued by an appropriate government agency and/or competent authority. If We receive requisite instructions from such authority, We shall refund the deposited amount from Your Unutilised Account to the source account, subject to applicable processing fees.</p>
                    <p>In the event Our Services are not available due to reasons outside Our control, including but not limited to any failure to perform due to unforeseen circumstances or cause beyond Our control, such as acts of god, war, terrorism, riots, embargoes, acts of civil or military authorities, fire, floods, accidents, pandemic, epidemic, network infrastructure failures, strikes, or shortages of transportation facilities, fuel, energy, labor or materials or any cancellation of services available on Our Platform (each a “Force Majeure Event”), then We reserve the right to cancel any Contest and process refund of the Pre - Designated Amount.</p>
                    <p>If there is any request for withdrawal of amount deposited in Unutilised Account, such refund request shall be processed net of GST i.e. applicable 28% GST paid on the amount deposited in Unutilised Account. GST amount shall not be refunded as per applicable government laws.</p>
                    <p>Any refund processed will be reflected in bank account within 9-10 days.</p>

                </div>
            </div>


            <BootsEmpireFooter />

        </>
    )
}

export default RefundPolicy