
import people1 from '../../assest/playnif50/stock3.webp'
import people3 from '../../assest/playnif50/stock4.jpg'
import people4 from '../../assest/playnif50/stock5.jpg'
import people6 from '../../assest/playnif50/stock6.webp'
import people7 from '../../assest/playnif50/stock exchange.jpg'
import people2 from '../../assest/playnif50/stocks.jpg'






const TopBannersData = [
    {
        id: 1,
        src: people7,
    },
    {
        id: 2,
        src: people3,
    },
    {
        id: 3,
        src: people4,
    },
    {
        id: 4,
        src: people6,
    },
    {
        id: 5,
        src: people1,
    },
    {
        id: 6,
        src: people2,
    },
];
export default TopBannersData;
