import polygon from '../../assest/BootsEmpire/about-us-page-banner.jpg'
import { Box } from "@mui/material"
import '../../styles/playnif50/AboutUsStyle.css'
import BootsEmpireHeader from "../../common/playnif50/header/BootsEmpireHeader"
import BootsEmpireFooter from '../../common/playnif50/Footer/BootsEmpireFooter'
import EnterpriseImg from '../../assest/BootsEmpire/slider-02-1.jpg'




const AdmissionProcess = () => {

    return (
        <>
            <BootsEmpireHeader />

            <Box className='Aboutus_topdiv'>
                <img src={polygon} alt="img" />
                <div className="hosp_aboutushead">
                    <h1>Admission Process</h1>
                </div>
            </Box>

            <div className=" bg-light-subtle about_textdiv">
                <div className="">
                    <p className=" fw-bold fs-1">Admission Process</p>
                    <p className=" fw-bold">Admission Process at Playnif50</p>
                    <p className="">At Playnif50, we strive to make the admission process smooth and transparent for all prospective students. Whether you are enrolling in our beginner courses or advanced programs, here is a step-by-step guide to our admission process:</p>

                    <p className=" fw-bold">Step 1: Course Selection</p>
                    <ul>
                        <li><strong>Explore Courses:</strong> Visit our website or contact our admissions office to explore the range of courses offered at Playnif50.</li>
                        <li><strong>Consultation:</strong> Schedule a consultation with our academic advisors to discuss your interests, goals, and which course best suits your needs.</li>
                    </ul>

                    <p className=" fw-bold">Step 2: Application</p>
                    <ul>
                        <li><strong>Online Application:</strong> Complete the online application form available on our website. Provide accurate personal information and educational background details.</li>
                        <li><strong>Offline Application:</strong> If applying offline, visit our campus or contact our admissions office for a physical application form.</li>
                    </ul>

                    <p className=" fw-bold">Step 3: Document Submission</p>
                    <p><strong>Required Documents:</strong> Prepare and submit necessary documents such as:</p>
                    <ul>
                        <li>Identification proof (e.g., Aadhar card, passport)</li>
                        <li>Educational certificates or transcripts</li>
                        <li>Passport-sized photographs</li>
                    </ul>

                    <p className=" fw-bold">Step 4: Application Review</p>
                    <ul>
                        <li><strong>Evaluation:</strong> Our admissions team will review your application and documents submitted.</li>
                        <li><strong>Communication:</strong> You will receive communication regarding the status of your application via email or phone.</li>
                    </ul>

                    <p className=" fw-bold">Step 5: Fee Payment</p>
                    <ul>
                        <li><strong>Fee Structure:</strong> Review the course fee structure available on our website or provided during consultation.</li>
                        <li><strong>Payment Options:</strong> Choose a convenient payment method (online transfer, credit/debit card, or bank deposit).</li>
                        <li><strong>Confirmation:</strong> Upon successful payment, you will receive confirmation of your enrollment.</li>
                    </ul>

                    <p className=" fw-bold">Step 6: Orientation and Welcome</p>
                    <ul>
                        <li><strong>Orientation Session:</strong> Attend an orientation session to familiarize yourself with Playnif50, our facilities, faculty, and course structure.</li>
                        <li><strong>Welcome Kit:</strong> Receive a welcome kit containing essential information about the academy and your course.</li>
                    </ul>


                    <p className=" fw-bold">Step 7: Commencement of Classes</p>
                    <ul>
                        <li><strong>Start of Classes:</strong> Classes typically commence according to the scheduled intake. Check your course start date and timetable.</li>
                        <li><strong>Access Learning Materials:</strong> Gain access to online learning platforms or receive course materials if enrolled in offline courses.</li>
                    </ul>

                    <p className=" fw-bold">Additional Information:</p>
                    <ul>
                        <li><strong>Support:</strong> Throughout the admission process, our admissions office and academic advisors are available to assist with any queries or concerns you may have.</li>
                        <li><strong>Enrollment Deadline:</strong> Be mindful of enrollment deadlines and ensure timely submission of applications and fees to secure your place in the course.</li>
                    </ul>

                    <p className=" fw-bold">Contact Us</p>
                    <p>For more information about our courses, admission process, or to schedule a consultation, please visit our website or contact our admissions office:</p>
                    <ul>
                        <li><strong>Website: </strong>www.playnif50.com</li>
                        <li><strong>Email:</strong>support@playnif50.com</li>
                        <li><strong>Phone: </strong>+91-9266968785</li>
                    </ul>
                    <p className="">Join Playnif50 and embark on a transformative journey to mastering the stock market. We look forward to welcoming you to our vibrant learning community.</p>
                </div>
            </div>


            <BootsEmpireFooter />

        </>
    )
}

export default AdmissionProcess