import BootsEmpireFooter from "../../common/playnif50/Footer/BootsEmpireFooter"
import GalleryPhotos from "../../common/playnif50/Gallery/Gallery"
import BootsEmpireHeader from "../../common/playnif50/header/BootsEmpireHeader"
import GallerData from "../../data/playnif50/GallerData"


const PhotoGallery = () => {
    return (
        <>
            <BootsEmpireHeader />

            <GalleryPhotos GalleryData={GallerData} title={"Photo Gallery"} />
            
            <BootsEmpireFooter />

        </>
    )
}

export default PhotoGallery