
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import { Button } from '@mui/material';

const PlansPricingData = [
    {
        Features: 'Number of Recorded Courses',
        start: <span className='fs-5'>3</span>,
        Silver: <span className='fs-5'>Unlimited</span>,
        Platinum: <span className='fs-5'>Unlimited</span>,
        Platinum2: <span className='fs-5'>Unlimited</span>
    },
    {
        Features: 'Number of Recorded Webinars',
        start: <span className='fs-5'>0</span>,
        Silver: <span className='fs-5'>Unlimited</span>,
        Platinum: <span className='fs-5'>Unlimited</span>,
        Platinum2: <span className='fs-5'>Unlimited</span>
    },
    {
        Features: 'Plan Validity',
        start: <span className='fs-5'>Free Forever</span>,
        Silver: <span className='fs-5'>6 Months</span>,
        Platinum: <span className='fs-5'>12 Months</span>,
        Platinum2: <span className='fs-5'>36 Months</span>
    },
    {
        Features: 'Live Webinar',
        start: <CancelIcon sx={{ color: 'red' }} />,
        Silver: <CancelIcon sx={{ color: 'red' }} />,
        Platinum: <CheckCircleIcon sx={{ color: 'green' }} />,
        Platinum2: <CheckCircleIcon sx={{ color: 'green' }} />
    },
    {
        Features: 'Live Class',
        start: <CancelIcon sx={{ color: 'red' }} />,
        Silver: <CancelIcon sx={{ color: 'red' }} />,
        Platinum: <CancelIcon sx={{ color: 'red' }} />,
        Platinum2: <span className='fs-5'>Additional 10% discount</span>
    },
    {
        Features: 'Live Seminar',
        start: <CancelIcon sx={{ color: 'red' }} />,
        Silver: <CancelIcon sx={{ color: 'red' }} />,
        Platinum: <CancelIcon sx={{ color: 'red' }} />,
        Platinum2: <span className='fs-5'>Additional 10% discount</span>
    },
    {
        Features: 'Email Support',
        start: <CancelIcon sx={{ color: 'red' }} />,
        Silver: <CheckCircleIcon sx={{ color: 'green' }} />,
        Platinum: <CheckCircleIcon sx={{ color: 'green' }} />,
        Platinum2: <CheckCircleIcon sx={{ color: 'green' }} />
    },
    {
        Features: 'Certifications on Course Completion',
        start: <CancelIcon sx={{ color: 'red' }} />,
        Silver: <CancelIcon sx={{ color: 'red' }} />,
        Platinum: <CheckCircleIcon sx={{ color: 'green' }} />,
        Platinum2: <CheckCircleIcon sx={{ color: 'green' }} />
    },
    {
        Features: 'Final Assessment',
        start: <CancelIcon sx={{ color: 'red' }} />,
        Silver: <CancelIcon sx={{ color: 'red' }} />,
        Platinum: <CheckCircleIcon sx={{ color: 'green' }} />,
        Platinum2: <CheckCircleIcon sx={{ color: 'green' }} />
    },
    {
        Features: 'Instant Doubt Clarification',
        start: <CancelIcon sx={{ color: 'red' }} />,
        Silver: <CancelIcon sx={{ color: 'red' }} />,
        Platinum: <CheckCircleIcon sx={{ color: 'green' }} />,
        Platinum2: <CheckCircleIcon sx={{ color: 'green' }} />
    },
]

export default PlansPricingData;

