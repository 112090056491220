

import featured3 from '../../assest/playnif50/Live-Class.jpg'
import featured2 from '../../assest/playnif50/coding_image.png'
import featured1 from '../../assest/playnif50/blog4.jpg'



const UpcomingLiveClassesData = [
    {
        id: 1,
        companyName:'LIVE Classes with Certification in Pro Options Trading Strategies',
        projectName:'CA Manish Singh',
        linkTage:'course_detail',
        imgSrc: featured3,
    },
    {
        id: 2,
        companyName:'Mastering Technical Analysis',
        projectName:'Mukta Dhamankar',
        linkTage:'course_detail',
        imgSrc: featured2,
    },
    {
        id: 3,
        companyName:'LIVE Classes with Certification in Pro Options Trading Strategies',
        projectName:'CA Manish Singh',
        linkTage:'course_detail',
        imgSrc: featured1,
    }
];
export default UpcomingLiveClassesData