
import featured3 from '../../assest/playnif50/webinar1.jpg'
import featured2 from '../../assest/playnif50/blog5.jpg'
import featured1 from '../../assest/playnif50/blog3.jpg'



const UpcomingFreeLiveWebinarData = [
    {
        id: 1,
        companyName:'Decoding the Option Chain: Insights for Smarter Trading',
        projectName:'Sonali Palande',
        linkTage:'course_detail',
        imgSrc: featured3,
    },
    {
        id: 2,
        companyName:'Importance of Volume in Price Action',
        projectName:'Mukta Dhamankar',
        linkTage:'course_detail',
        imgSrc: featured2,
    },
    {
        id: 3,
        companyName:'Decoding the Option Chain: Insights for Smarter Trading',
        projectName:'Sonali Palande',
        linkTage:'course_detail',
        imgSrc: featured1,
    }
];
export default UpcomingFreeLiveWebinarData