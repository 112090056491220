

import Accordion from '@mui/material/Accordion';
import AccordionActions from '@mui/material/AccordionActions';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useState } from "react"
import { Box, Typography } from '@mui/material';
import '../styles/playnif50/faqStyle.css'
import polygon from '../assest/BootsEmpire/question-marks.jpg'


const FaqComponent = ({ FaqData }) => {

    const [expandedAccordion, setExpandedAccordion] = useState(null);

    const handleAccordionChange = (panel) => (event, isExpanded) => {
        setExpandedAccordion(isExpanded ? panel : null);
    };

    return (
        <>
            <div className=" bg-black">
                <div className="maq_accordion">
                    <h1 className=' fw-bold mt-4 mb-5 text-center text-white'>Frequently Asked Questions</h1>
                    {FaqData?.map((accordionItem) => (
                        <Accordion
                            className='maq_accordion-bigdiv'
                            key={accordionItem.id}
                            expanded={expandedAccordion === accordionItem.id}
                            onChange={handleAccordionChange(accordionItem.id)}
                        >
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls={`panel${accordionItem.id}-content`}
                                id={`panel${accordionItem.id}-header`}
                            >
                                <Typography className="maq_bigdiv-ques">{accordionItem.title}</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Typography className="maq_bigdiv-answr"> {accordionItem.details}</Typography>
                            </AccordionDetails>
                        </Accordion>
                    ))}
                </div>
            </div>



        </>
    )
}

export default FaqComponent