
import { Button, Rating } from "@mui/material"
import BootsEmpireFooter from "../../common/playnif50/Footer/BootsEmpireFooter"
import BootsEmpireHeader from "../../common/playnif50/header/BootsEmpireHeader"
import { Link } from "react-router-dom"
import AdvancedStockMarketCourseData from "../../data/playnif50/AdvancedStockMarketCourseData"


const AdvancedTechnicalAnalysisCourse = () => {
    return (
        <>
            <BootsEmpireHeader />
            <div className="FeaturedProject_slide py-5">
                <h1 className='fw-bold text-center py-3'>Advanced Technical Analysis Course</h1>
                <div className=" d-flex justify-content-center flex-wrap">
                    {AdvancedStockMarketCourseData?.map((item, index) => (
                        <div className="project_fetaureddiv m-2">
                            <Link to={`/${item.linkTage}`}>
                                <div>
                                    <img src={item.imgSrc} alt="img" />
                                </div>
                                <div>
                                    <p>{item.price} <span>{item.Originalprice}</span></p>
                                    <h6>{item.companyName}</h6>
                                </div>
                            </Link>
                            <div className="teaminfo">
                                <div>
                                    <p><Link><Rating size="small" name="read-only" value={'5'} readOnly /> ({item.Rating}) </Link></p>
                                </div>
                                <div>
                                    <p>{item.projectName}</p>
                                </div>
                            </div>
                            <Button className=' bg-primary-subtle' fullWidth>Add to cart</Button>
                        </div>

                    ))}
                </div>
            </div >
            <BootsEmpireFooter />
        </>
    )
}

export default AdvancedTechnicalAnalysisCourse