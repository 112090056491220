
import polygon from '../../assest/BootsEmpire/about-us-page-banner.jpg'
import { Box } from "@mui/material"
import '../../styles/playnif50/AboutUsStyle.css'
import BootsEmpireHeader from "../../common/playnif50/header/BootsEmpireHeader"
import BootsEmpireFooter from '../../common/playnif50/Footer/BootsEmpireFooter'
import EnterpriseImg from '../../assest/BootsEmpire/slider-02-1.jpg'




const RulesRegulations = () => {

    return (
        <>
            <BootsEmpireHeader />

            <Box className='Aboutus_topdiv'>
                <img src={polygon} alt="img" />
                <div className="hosp_aboutushead">
                    <h1>Rules Regulations</h1>
                </div>
            </Box>

            <div className=" bg-light-subtle about_textdiv">
                <div className="">
                    <p className=" fw-bold fs-1">Rules Regulations</p>
                    <p className=" fw-bold">Rules and Regulations</p>
                    <p className="">To maintain a conducive learning environment and ensure a positive experience for all students, Playnif50 has established the following rules and regulations. These guidelines are designed to promote fairness, respect, and academic integrity throughout your educational journey with us.</p>


                    <p className=" fw-bold">1. Code of Conduct</p>
                    <ul>
                        <li><strong> Respect: </strong> Treat all instructors, staff, fellow students, and guests with respect and courtesy.</li>
                        <li><strong> Professionalism: </strong> Maintain professional behavior during classes, seminars, and any academy-related activities.</li>
                        <li><strong> Non-Discrimination: </strong> Avoid discrimination or harassment based on race, ethnicity, gender, religion, or any other protected characteristic.</li>
                        <li><strong> Plagiarism: </strong> Uphold academic honesty by refraining from plagiarism or any form of academic dishonesty.</li>
                        <li><strong> Attendance: </strong> Attend all classes and sessions punctually, as regular attendance contributes to your learning and engagement.</li>
                    </ul>

                    <p className=" fw-bold">2. Academic Integrity</p>
                    <ul>
                        <li><strong> Original Work:</strong> Submit only your original work for assignments, projects, and assessments.</li>
                        <li><strong> Citations:</strong>Properly cite and reference sources used in research or assignments according to academic standards.</li>
                        <li><strong> Collaboration:</strong>Collaborate with classmates ethically and within the parameters set by instructors for group projects or assignments.</li>
                    </ul>

                    <p className=" fw-bold">3. Use of Facilities</p>
                    <ul>
                        <li><strong> Equipment:</strong> Use academy facilities, equipment, and resources responsibly and as intended.</li>
                        <li><strong> Internet Use:</strong> Adhere to acceptable internet usage policies during classes and academy-related activities.</li>
                        <li><strong> Cleanliness: </strong> Maintain cleanliness and tidiness in shared spaces, including classrooms and common areas.</li>
                    </ul>

                    <p className=" fw-bold">4. Communication</p>
                    <ul>
                        <li><strong> Official Channels: </strong> Use official academy communication channels for inquiries, feedback, or concerns.</li>
                        <li><strong> Timeliness: </strong> Respond promptly to academy communications and notifications regarding course updates or administrative matters.</li>
                    </ul>

                    <p className=" fw-bold">5. Disciplinary Actions</p>
                    <ul>
                        <li><strong> Violations: </strong> Violations of rules, regulations, or codes of conduct may result in disciplinary actions, including warnings, probation, or in severe cases, dismissal from the academy.</li>
                        <li><strong> Appeals:</strong> Students have the right to appeal disciplinary decisions through established procedures, ensuring fairness and transparency.</li>
                    </ul>

                    <p className=" fw-bold">6. Health and Safety</p>
                    <ul>
                        <li><strong> Health Guidelines:</strong> Adhere to health and safety guidelines issued by the academy, especially in emergencies or during health crises.</li>
                        <li><strong> Emergency Procedures:</strong> Familiarize yourself with emergency procedures and evacuation protocols applicable to academy premises.</li>
                    </ul>

                    <p className=" fw-bold">7. Privacy and Confidentiality</p>
                    <ul>
                        <li><strong> Data Protection: </strong> Respect the privacy and confidentiality of personal information and data shared during courses or academy-related activities.</li>
                        <li><strong> Non-Disclosure: </strong> Do not disclose confidential academy information, proprietary course materials, or sensitive data without proper authorization.</li>
                    </ul>

                    <p className=" fw-bold">8. Financial Obligations</p>
                    <ul>
                        <li><strong> Fee Payments:</strong> Fulfill financial obligations promptly, including course fees, as outlined in the admission agreement or financial policies of the academy.</li>
                        <li><strong> Refunds: </strong>Follow procedures for requesting refunds, if applicable, based on academy policies and timelines.</li>
                    </ul>

                    <p className=" fw-bold">9. Code of Ethics</p>
                    <ul>
                        <li><strong> Ethical Trading: </strong>If involved in practical trading exercises or simulations, adhere to ethical trading practices and legal guidelines.</li>
                        <li><strong> Compliance:</strong> Comply with all applicable laws, regulations, and ethical standards relevant to stock market trading and investments.</li>
                    </ul>

                    <p className=" fw-bold">Contact Us</p>
                    <p>For any questions or clarifications regarding rules and regulations at Playnif50, please contact our administrative office or refer to the detailed handbook provided to all enrolled students. We are committed to maintaining a supportive and enriching learning environment for all our students.</p>
                    <p>Contact Information:</p>
                    <ul>
                        <li><strong>Website: </strong>www.playnif50.com</li>
                        <li><strong>Email:</strong>support@playnif50.com</li>
                        <li><strong>Phone: </strong>+91-9266968785</li>
                    </ul>
                    <p className="">Thank you for choosing Playnif50. We look forward to supporting you in your educational journey and beyond.</p>
                </div>
            </div>


            <BootsEmpireFooter />

        </>
    )
}

export default RulesRegulations