


import featured1 from '../../assest/playnif50/blog4.jpg'
import featured2 from '../../assest/playnif50/blog5.jpg'
import featured3 from '../../assest/playnif50/stock6.webp'
import featured4 from '../../assest/playnif50/blog1.jpg'
import featured5 from '../../assest/playnif50/blog2.jpg'
import featured6 from '../../assest/playnif50/stock4.jpg'



const TechnicalAnalysisCourseData = [
    {
        id: 1,
        companyName:'Technical Charts and Trendlines for Beginners',
        projectName:'6.8K Enrollments',
        price:'₹ 399',        
        Originalprice:'₹ 999',
        Rating:'179',
        linkTage:'course_detail',
        imgSrc: featured1,
    },
    {
        id: 2,
        companyName:'Technical Charts and Trendlines for Beginners',
        projectName:'6.8K Enrollments',
        price:'₹ 399',        
        Originalprice:'₹ 999',
        Rating:'179',
        linkTage:'course_detail',
        imgSrc: featured2,
    },
    {
        id: 3,
        companyName:'Technical Charts and Trendlines for Beginners',
        projectName:'6.8K Enrollments',
        price:'₹ 399',        
        Originalprice:'₹ 999',
        Rating:'179',
        linkTage:'course_detail',
        imgSrc: featured3,
    }, {
        id: 4,
        companyName:'Technical Charts and Trendlines for Beginners ',
        projectName:'6.8K Enrollments',
        price:'₹ 399',        
        Originalprice:'₹ 999',
        Rating:'179',
        linkTage:'course_detail',
        imgSrc: featured4,
    }, {
        id: 5,
        companyName:'Technical Charts and Trendlines for Beginners ',
        projectName:'6.8K Enrollments',
        price:'₹ 399',        
        Originalprice:'₹ 999',
        Rating:'179',
        linkTage:'course_detail',
        imgSrc: featured5,
    }, {
        id: 6,
        companyName:'Technical Charts and Trendlines for Beginners ',
        projectName:'6.8K Enrollments',
        price:'₹ 399',        
        Originalprice:'₹ 999',
        Rating:'179',
        linkTage:'course_detail',
        imgSrc: featured6,
    }
];
export default TechnicalAnalysisCourseData