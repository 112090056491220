import './BootsEmpireFooterStyle.css'
import Logo from "../../../assest/BootsEmpire/Whatsapp-Chat-Icon-1.webp";
import { ErrorMessage, Field, Form, Formik } from "formik"
import * as yup from 'yup'
import { Box, Button } from '@mui/material';
import { Link } from 'react-router-dom';
import { MdLocationPin, MdCall, MdEmail } from "react-icons/md";
import android from "../../../assest/playnif50/android.png";
import appdebug from "../../../assest/playnif50/playnif50.apk";


const BootsEmpireFooter = () => {
    const defalutValue = {
        EmailAddress: '',

    }

    const validationSceema = yup.object().shape({
        // fieldLbale: yup.string().required('SS').min(10).max(10),
        EmailAddress: yup.string().required('Email is Requird!'),

    })
    const handleSubmit = (value) => {
        console.log(value);

    }
    return (
        <>

            <div className="newfooter">
                <div className="">
                    <div className="footer_links">
                        <div className="row">
                            <div className="col-md-3">
                                <div className="footerlnk-head">Company</div>
                                <ul className="menu_div">
                                    <li><Link to="/about_us">About</Link></li>
                                    <li><Link to="/contact_us">Contact Us</Link></li>
                                    <li><Link to="/FAQ">FAQ’s</Link></li>
                                    <li><Link to="/photo_gallery">Photo Gallery</Link></li>
                                    <li><Link to="/video_gallery">Video Gallery </Link></li>

                                    {/* <li><Link to="/">Why Us</Link></li> */}
                                </ul>

                                <div className="footerlnk-head">Follow Us</div>
                                <ul className="soc_ul_footer">
                                    <li>
                                        <a href="/" target="_blank">
                                            <img src="https://www.indianretailer.com/restaurant/themes/menshealth/images/fb.png" alt="Facebook" />
                                        </a>
                                    </li>
                                    <li>
                                        <a href="/" target="_blank">
                                            <img src="https://www.indianretailer.com/restaurant/themes/menshealth/images/in.png" alt="Instagram" />
                                        </a>
                                    </li>
                                    <li>
                                        <a href="/" target="_blank">
                                            <img src="https://www.indianretailer.com/restaurant/themes/menshealth/images/li.png" alt="LinkedIn" />
                                        </a>
                                    </li>
                                    <li>
                                        <a href="/" target="_blank">
                                            <img src="https://www.indianretailer.com/restaurant/themes/menshealth/images/tw.png" alt="Twitter" />
                                        </a>
                                    </li>
                                </ul>
                            </div>

                            <div className="col-md-3">
                                <div className="footerlnk-head">Quick Links</div>
                                <ul className="menu_div">
                                    {/* <li><Link to="">Quizzes</Link></li> */}
                                    {/* <li><Link to="">Glossary</Link></li> */}
                                    <li><Link to="/blogs">Blogs</Link></li>
                                    <li><Link to="/how_to_play">How to play</Link></li>
                                    <li><Link to="/refund_policy">Refund Policy</Link></li>
                                    <li><Link to="/privacy_policy">Privacy Policy</Link></li>
                                    <li><Link to="/terms_conditions">Terms & Conditions</Link></li>
                                </ul>
                            </div>


                            <div className="col-md-3">
                                <div className="footerlnk-head">Top Courses</div>
                                <ul className="menu_div">
                                    <li><Link to="/course_detail">Stock Investing Masterclass</Link></li>
                                    <li><Link to="/course_detail">Value Investing Course For Beginners</Link></li>
                                    <li><Link to="/course_detail">Learn Futures Trading in India</Link></li>
                                    <li><Link to="/course_detail">Candlesticks Trading</Link></li>
                                    <li><Link to="/course_detail">Options Trading for Beginners</Link></li>
                                </ul>
                                <a href={appdebug} download>
                                    <Button className='buttan'><img className='DownloadTheApp' src={android} alt="img" /><span>Download APP NOW!</span></Button>
                                </a>
                            </div>

                            <div className="col-md-3">
                                <div className="footerlnk-head">Office</div>
                                <ul className="menu_div">
                                    <li className="fw-bold">PLAYNIF50</li>
                                    <li className=' mt-3'><MdLocationPin className=' me-2 fs-3' />103 sagar deep , kanishka complex , saini enclave karkardooma , delhi 92</li>
                                    <li className=' mt-3'><MdCall className=' me-2 fs-3' /><Link to="tel:+91-9266968785">+91-9266968785</Link></li>
                                    <li className=' mt-3'><MdEmail className=' me-2 fs-3' /><Link to="mailto:support@playnif50.com">support@playnif50.com</Link></li>
                                </ul>
                                <img className='whatsappimg' src={Logo} alt="img" />
                            </div>
                        </div>
                    </div>

                </div>
            </div>
            <div className="copyright">
                <div className="copy">
                    <p>Copyright © 2024 Playnif50 Powered By <Link to={'https://www.abarissoftech.com/'}> Abaris Softech </Link></p>
                </div>
            </div>


        </>
    )
}

export default BootsEmpireFooter