import people1 from '../../assest/playnif50/blog1.jpg'
import people2 from '../../assest/playnif50/blog2.jpg'
import people3 from '../../assest/playnif50/blog3.jpg'
import people4 from '../../assest/playnif50/blog4.jpg'
import people5 from '../../assest/playnif50/blog5.jpg'
import people6 from '../../assest/playnif50/blog1.jpg'
import people7 from '../../assest/playnif50/blog2.jpg'
import people8 from '../../assest/playnif50/blog3.jpg'



const BlogsData = [
    {
        id: 1,
        heading:'The Future of Blockchain: Exploring Upcoming ICOs in 2024',
        Postedby:"Anirban Bose",
        textdetail:'This article will take readers through the highly awaited ICOs for the year 2024 — what they have to offer in terms of industry impacts, as well as some advice for prospective investors.',
        imgSrc: people1,
    },
    {
        id: 2,
        heading:'The Future of Blockchain: Exploring Upcoming ICOs in 2024',
        Postedby:"Anirban Bose",
        textdetail:'This article will take readers through the highly awaited ICOs for the year 2024 — what they have to offer in terms of industry impacts, as well as some advice for prospective investors.',
        imgSrc: people2,
    },

    {
        id: 3,
        heading:'The Future of Blockchain: Exploring Upcoming ICOs in 2024 ',
        Postedby:"Anirban Bose",
        textdetail:'This article will take readers through the highly awaited ICOs for the year 2024 — what they have to offer in terms of industry impacts, as well as some advice for prospective investors.',
        imgSrc: people3,
    } ,
     {
        id: 4,
        heading:'The Future of Blockchain: Exploring Upcoming ICOs in 2024',
        Postedby:"Anirban Bose",
        textdetail:'This article will take readers through the highly awaited ICOs for the year 2024 — what they have to offer in terms of industry impacts, as well as some advice for prospective investors.',
        imgSrc: people4,
    },
     {
        id: 5,
        heading:'The Future of Blockchain: Exploring Upcoming ICOs in 2024 ',
        Postedby:"Anirban Bose",
        textdetail:'This article will take readers through the highly awaited ICOs for the year 2024 — what they have to offer in terms of industry impacts, as well as some advice for prospective investors.',
        imgSrc: people5,
    }, {
        id: 6,
        heading:'The Future of Blockchain: Exploring Upcoming ICOs in 2024 ',
        Postedby:"Anirban Bose",
        textdetail:'This article will take readers through the highly awaited ICOs for the year 2024 — what they have to offer in terms of industry impacts, as well as some advice for prospective investors.',
        imgSrc: people6,
    }
    , {
        id: 7,
        heading:'The Future of Blockchain: Exploring Upcoming ICOs in 2024 ',
        Postedby:"Anirban Bose",
        textdetail:'This article will take readers through the highly awaited ICOs for the year 2024 — what they have to offer in terms of industry impacts, as well as some advice for prospective investors.',
        imgSrc: people7,
    }, {
        id: 8,
        heading:'The Future of Blockchain: Exploring Upcoming ICOs in 2024 ',
        Postedby:"Anirban Bose",
        textdetail:'This article will take readers through the highly awaited ICOs for the year 2024 — what they have to offer in terms of industry impacts, as well as some advice for prospective investors.',
        imgSrc: people8,
    }
   
];
export default BlogsData