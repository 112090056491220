

import featured1 from '../../assest/playnif50/stock exchange.jpg'
import featured2 from '../../assest/playnif50/stock3.webp'
import featured3 from '../../assest/playnif50/stock4.jpg'
import featured4 from '../../assest/playnif50/blog4.jpg'
import featured5 from '../../assest/playnif50/blog5.jpg'
import featured6 from '../../assest/playnif50/stock4.jpg'



const OptionsTradingCourseData = [
    {
        id: 1,
        companyName:'How to Trade Nifty And Bank Nifty?',
        projectName:'3.8K Enrollments',
        price:'₹ 1999',        
        Originalprice:'₹ 3999',
        Rating:'137',
        linkTage:'course_detail',
        imgSrc: featured1,
    },
    {
        id: 2,
        companyName:'How to Trade Nifty And Bank Nifty?',
        projectName:'3.8K Enrollments',
        price:'₹ 1999',        
        Originalprice:'₹ 3999',
        Rating:'137',
        linkTage:'course_detail',
        imgSrc: featured2,
    },
    {
        id: 3,
        companyName:'How to Trade Nifty And Bank Nifty?',
        projectName:'3.8K Enrollments',
        price:'₹ 1999',        
        Originalprice:'₹ 3999',
        Rating:'137',
        linkTage:'course_detail',
        imgSrc: featured3,
    }, {
        id: 4,
        companyName:'How to Trade Nifty And Bank Nifty? ',
        projectName:'3.8K Enrollments',
        price:'₹ 1999',        
        Originalprice:'₹ 3999',
        Rating:'137',
        linkTage:'course_detail',
        imgSrc: featured4,
    }, {
        id: 5,
        companyName:'How to Trade Nifty And Bank Nifty? ',
        projectName:'3.8K Enrollments',
        price:'₹ 1999',        
        Originalprice:'₹ 3999',
        Rating:'137',
        linkTage:'course_detail',
        imgSrc: featured5,
    }, {
        id: 6,
        companyName:'How to Trade Nifty And Bank Nifty? ',
        projectName:'3.8K Enrollments',
        price:'₹ 1999',        
        Originalprice:'₹ 3999',
        Rating:'137',
        linkTage:'course_detail',
        imgSrc: featured6,
    }
];
export default OptionsTradingCourseData