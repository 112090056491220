
import people1 from '../../assest/playnif50/add-group.png'
import people2 from '../../assest/playnif50/google-docs.png'
import people3 from '../../assest/playnif50/videobook.png'
import people4 from '../../assest/playnif50/driving-license.png'




const SustainabilityData = [
    {
        id: 1,
        heading: "93095",
        textdetail: "Learners",
        // linktag:"Seehot",
        imgSrc: people1,
    },
    {
        id: 2,
        heading: "70+",
        textdetail: "Courses & Webinars.",
        // linktag:"Checkomains",
        imgSrc: people2,
    },
    {
        id: 3,
        heading: "35+",
        textdetail: "Mentors & Instructors.",
        // linktag:'Browsemains',
        imgSrc: people3,
    },

    {
        id: 4,
        heading: "250+",
        textdetail: "Learning Hours.",
        // linktag:'Browsemains',
        imgSrc: people4,
    }
];
export default SustainabilityData