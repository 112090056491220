import React from 'react';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import '../styles/playnif50/FeaturedProjectSliderStyle.css';
import { Link } from 'react-router-dom';
import { Button, Rating } from '@mui/material';
import { FaCircleRight } from "react-icons/fa6";


const FeaturedProjectSlider = ({ title, detailsData }) => {

    const settings = {
        dots: false,
        infinite: true,
        speed: 700,
        slidesToScroll: 1,
        arrows: true,
        autoplay: true,
        responsive: [
            {
                breakpoint: 450,
                settings: {
                    slidesToShow: 1,
                    centerMode: true,
                    centerPadding: '1px',
                },
            },
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 1,
                    centerMode: true,
                    centerPadding: '1px',
                },
            },
            {
                breakpoint: 9999, // A large value to ensure the last breakpoint always applies
                settings: {
                    slidesToShow: 3,
                },
            }
        ],
    };

    return (
        <>
            <div className="FeaturedProject_slide">
                {/* <h1 className='fw-bold ms-3'>{title}</h1> */}
                <Slider {...settings}>
                    {detailsData?.map((item, index) => (
                        <div className="project_fetaureddiv">
                            <Link to={`/${item.linkTage}`}>
                                <div>
                                    <img src={item.imgSrc} alt="img" />
                                </div>
                                <div>
                                    <p>{item.price} <span>{item.Originalprice}</span></p>
                                    <h6>{item.companyName}</h6>
                                </div>
                            </Link>
                            <div className="teaminfo">
                                <div>
                                    <p><Link><Rating size="small" name="read-only" value={'5'} readOnly /> ({item.Rating}) </Link></p>
                                </div>
                                <div>
                                    <p>{item.projectName}</p>
                                </div>
                            </div>
                            <Button className=' bg-primary-subtle' fullWidth>Add to cart</Button>
                        </div>

                    ))}
                </Slider >
            </div >
        </>
    )
}

export default FeaturedProjectSlider
