
import client1 from '../../assest/playnif50/EventPartner1.jpeg'
import client2 from '../../assest/playnif50/EventPartner2.jpeg'
import client3 from '../../assest/playnif50/EventPartner3.jpeg'
import client4 from '../../assest/playnif50/EventPartner4.jpeg'




const EventPartners = [
    {
        id: 1,
        imgSrc: client1,
        // name: 'Chennai client'
    },
    {
        id: 2,
        imgSrc: client2,
        // name: 'Delhi client'
    },
    {
        id: 3,
        imgSrc: client3,
        // name: 'Kochi client'

    }, {
        id: 4,
        imgSrc: client4,
        // name: 'NHAI Projects'
    }
];
export default EventPartners

