const FaqData = [
    {
        id: 1,
        title: ' What is the Subscription model on PlayNif50?',
        details:'We offer three different plans, namely Basic, Elite, and Platinum plan, each with different validity periods. While access to all premium courses and webinars remains the same across all plans, there are differences in features. The Basic plan does not provide certificate of completion, instant doubt clarification by experts and course self-assessment, while these features are included in Elite and Platinum plans. For more details on each plan',
    },
    {
        id: 2,
        title: " Can I get a refund if I cancel my subscription at any time??",
        details:'As a part of company policy, there is no refund policy once the subscription is purchased.',    
    },
    {
        id: 3,
        title: "What all are included in the subscription plan??",
        details:' The subscription plans include all the courses and webinars listed under the Courses and Webinars section.',    
    },
    {
        id: 4,
        title: " Can I change my subscription to any other plan anytime??",
        details:' Yes, you could upgrade to any higher plan. The plan validity will get added to the existing plan.',    
    },
    {
        id: 5,
        title: "What are the different payment methods at PlayNif50?",
        details:"PlayNif50 offers several payment methods like Domestic and International Credit & Debit cards, EMIs ( Credit/Debit Cards & Cardless), PayLater, Netbanking from 58 banks, UPI etc, through Razorpay on the platform.",    
    },
    {
        id: 6,
        title: "What are the different payment methods at PlayNif50?",
        details:'PlayNif50 offers several payment methods like Domestic and International Credit & Debit cards, EMIs ( Credit/Debit Cards & Cardless), PayLater, Netbanking from 58 banks, UPI etc, through Razorpay on the platform.',    
    },
    {
        id: 7,
        title: "Why my payments are getting failed?",
        details:'f you’re having trouble completing a purchase on PlayNif50, please: 1. Double check the bank details that you have entered for the selected payment method. 2. If you’re still receiving an error message that the payment could not be completed, please contact your bank. Some of the most common reasons banks decline payments include insufficient funds, card purchase limitations, and card security policies. For example, some cards have usage limitations, and will automatically block payment attempts after a certain threshold has been reached.',    
    },
    {
        id: 8,
        title: "How do I purchase a subscription plan?",
        details:'To Purchase a subscription plan : • Visit pricing page , and select the plan that works out for you. However we recommend you for the Elite plan. • Add the plan to the cart by clicking on subscribe of the selected plan • Check for any discounts available at the cart. • Click on Proceed to Pay and select the payment method of your choice. • Enjoy access to premium courses and webinars till your plan is active.',    
    },
    {
        id: 9,
        title: "How can I get my payment receipt after successfull purchase?",
        details:'You will receive your payment receipt after the successfull payment on your registered email.',    
    }
];

export default FaqData