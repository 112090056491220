import BootsEmpireFooter from "../../common/playnif50/Footer/BootsEmpireFooter"
import BootsEmpireHeader from "../../common/playnif50/header/BootsEmpireHeader"
import img2 from '../../assest/playnif50/college_workshop_homepage.jpeg'
import { Box, Button, Rating } from "@mui/material"
import '../../styles/playnif50/CourseDetailStyle.css'
import { Link } from "react-router-dom"
import { FaRegChessQueen } from "react-icons/fa6";
import cover from '../../assest/BootsEmpire/3.png'
import SustainabilityData from "../../data/playnif50/SustainabilityData"
import FeaturesData from "../../data/playnif50/FeaturesData"
import { IoMdCheckboxOutline } from "react-icons/io";
import linkedin from '../../assest/playnif50/linkedin.png'
import youtube from '../../assest/playnif50/youtube.png'
import FeaturedProjectSlider from "../../components/FeaturedProjectSlider"
import FreeCourseData from "../../data/playnif50/FreeCourseData"
import FaqComponent from "../../components/FaqComponent"
import CourseDetailFaqData from "../../data/playnif50/CourseDetailFaqData"
import PricingContainer from "../../components/PricingContainer"


const CourseDetail = () => {
  return (

    <>
      <BootsEmpireHeader />

      <Box className='Boost_about_div'>
        <Box className="Boost_about_div_home row">
          <div className="textdiv col-lg-6 col-12">
            <div className="PaidCourseminiDiv">
              <p>Paid Course</p>
              <p className="choice_p">FinGrad's Choice</p>
            </div>
            <h6>Options Trading With Technical Analysis Masterclass</h6>
            <p>Are you an options trader looking to take your trading to the next level? Then join us for Options Trading With Technical Analysis Masterclass! Course will teach you in detail the core concepts of options and technical analysis so that you can confidently enter the markets and make profitable trades.</p>
            <div className="PaidCourseminiDiv">
              <p className=" fw-bold">4.6 <Link className=" mx-1"><Rating size="small" name="read-only" value={'5'} readOnly /></Link> (167 reviews) .</p>
              <p className=" fw-bold ms-4">4,845+ Enrollments</p>
            </div>
            <Button className="morebtn mb-1">Get it at ₹399/ <span style={{ fontSize: '13px' }} className=" text-decoration-line-through me-1">₹999 </span> (61% off)</Button>
            <div className="PaidCourseminiDiv">
              <p>Or Get this with Premium Subscription <FaRegChessQueen style={{ color: 'orange' }} className=" fs-6 ms-1" /></p>
              <p className="choice_p">View Plans</p>
            </div>
          </div>
          <div className="imggdiv col-lg-6 col-12">
            <img src={img2} alt="img" />
            <div className="profile_nameDiv">
              <div>
                <img src={cover} alt="img" />
              </div>
              <div className=" ms-3 text-start">
                <h4 className=" fw-bold mb-1">CA Mohit Gupta</h4>
                <p className=" mb-0">Chartered Accountant, Trader & Investor</p>
              </div>

            </div>
          </div>
        </Box>
      </Box>

      <div className="Features_div">
        <h1 className=" fw-bold py-3">Features</h1>
        <div className="Features_slidediv">
          {FeaturesData?.map((item, index) => (
            <Link>
              <div key={item.id} className="Features_div_slide">
                <img src={item.imgSrc} alt={`img-${item.id}`} />
                <h4>{item.heading}</h4>
                {/* <p>{item.textdetail}</p> */}
              </div>
            </Link>

          ))}
        </div >
      </div >

      <div className="AboutthisCourse">
        <h1>Course Overview</h1>

        <div>
          <h4>About this Course</h4>
          <p>Trading Options has never been easy. In fact, 90% of options traders lose money. Options Trading with Technical Analysis Masterclass has been designed to help you fall into that top 10% bracket of elite traders who consistently make money trading options.  </p>
          <p>To start something, it’s important to understand the basics of it. So, in the Options Trading with Technical Analysis Masterclass, we have first covered the basics. Starting from the history of Options to how the Option Greeks work, we have covered everything in detail with practical examples of how they actually work in the market.</p>
          <p>In the second part of the Options Trading with Technical Analysis Masterclass, we focussed on analyzing the markets using technical concepts. More specifically, we have taught you to analyze current market trends and make educated decisions about what options to buy and sell using technical analysis.</p>
          <p>We have also shared methods that will help in maximizing profits and minimizing losses. </p>
          <p>Along with all this, we have also given dedicated time to teach intraday trading concepts with more focus on Expiry Day Trading. </p>
          <p>To top it off, we have ended the Options Trading with Technical Analysis Masterclass by discussing the mental model one should develop to be a better options trader.</p>


          <h4>Requirements</h4>
          <p>Options Trading with Technical Analysis Masterclass requires entry-level experience in options trading. If you are an absolute beginner with zero experience we suggest taking the "Complete Course on Options Trading for Beginners" first.</p>

          <h4>Targeted For</h4>
          <p>Options Trading with Technical Analysis Masterclass has been primarily made for traders who have already spent some time in the market but are struggling to find their edge and consistently make money. </p>
          <p>Apart from this Intermediate to Advanced, all will derive value from this course, especially through the trading strategies that we have taught.</p>
        </div>
      </div>

      <div className="WhatwillyouLearn">
        <h1>What will you Learn?</h1>
        <div className=" Divmakedflex">
          <div className="divOutline1">
            <div><IoMdCheckboxOutline className="iconn" /></div>
            <div>Fundamentals of Options Trading</div>
          </div>

          <div className="divOutline2">
            <div><IoMdCheckboxOutline className="iconn" /></div>
            <div>Technical Analysis Complimenting Options Trading</div>
          </div>


          <div className="divOutline3">
            <div><IoMdCheckboxOutline className="iconn" /></div>
            <div> Intraday and Expiry Day Options Trading Strategies</div>
          </div>

          <div className="divOutline4">
            <div><IoMdCheckboxOutline className="iconn" /></div>
            <div>Trade Management using Options Trading</div>
          </div>

        </div>
      </div>






      <Box className='Boost_about_div'>
        <h1 className=" py-5">About Instructor</h1>
        <Box className="Boost_about_div_home row">
          <div className="imggdiv col-lg-6 col-12">
            <img src={cover} alt="img" />
          </div>
          <div className="textdiv col-lg-6 col-12">
            <h6>CA Mohit Gupta</h6>
            <p className=" fw-medium pt-0">Chartered Accountant, Trader & Investor</p>
            <p className="pb-4">CA Mohit Gupta has been successfully trading in the stock market for 5+ years and has personally developed many strategies for generating regular income. His core area of interest includes financial services, capital market, and research analysis.</p>
            <Link><img style={{ width: '35px', marginRight: '10px' }} src={linkedin} alt="img" /></Link>
            <Link><img style={{ width: '35px' }} src={youtube} alt="img" /></Link>
          </div>
        </Box>
      </Box>

      
      <div className="px-4 bg-black">
        <h1 className=" fw-bold pt-5 text-white ps-1">Explore more</h1>
        <FeaturedProjectSlider detailsData={FreeCourseData} />
      </div>

      <FaqComponent FaqData={CourseDetailFaqData} />

      {/* <div className=" pb-5 bg-black">
        <PricingContainer />
      </div> */}

      <BootsEmpireFooter />

    </>
  )
}

export default CourseDetail