
import people1 from '../../assest/playnif50/bar-chart.png'
import people2 from '../../assest/playnif50/translate.png'
import people3 from '../../assest/playnif50/videobook.png'
import people4 from '../../assest/playnif50/stopwatch.png'




const FeaturesData = [
    {
        id: 1,
        heading: "Intermediate",
        // linktag:"Seehot",
        imgSrc: people1,
    },
    {
        id: 2,
        heading: "On Demand Video",
        // linktag:"Checkomains",
        imgSrc: people3,
    },
    {
        id: 3,
        heading: "240 min of Video",
        // linktag:'Browsemains',
        imgSrc: people4,
    },

    {
        id: 4,
        heading: "Hindi",
        // linktag:'Browsemains',
        imgSrc: people2,
    }
];
export default FeaturesData